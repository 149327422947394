import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import { CssAndFramerMotionProvider, PageLoadIndicator } from '@graphcommerce/next-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppProps } from 'next/app'
import Script from 'next/script'
import { CookieFirstScript } from '../components/CookieFirst/CookieFirstScript'
import { CustomerServiceChat } from '../components/CustomerService/CustomerServiceChat'
import { KlaviyoScript } from '../components/Klaviyo/KlaviyoScript'
import { lightTheme } from '../components/theme'
import { I18nProvider } from '../lib/i18n/I18nProvider'
import '../public/global.css'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'en' } = router

  return (
    <CssAndFramerMotionProvider {...props}>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props}>
          <ThemeProvider theme={lightTheme}>
            <GlobalHead />
            <CssBaseline />
            <PageLoadIndicator />
            <CookieFirstScript />
            <KlaviyoScript />
            <Script
              async
              strategy='afterInteractive'
              src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
            />
            <FramerNextPages {...props} />
            <CustomerServiceChat />
          </ThemeProvider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
