/// <reference types="@graphcommerce/next-ui/types" />

import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiFabSizes,
  MuiSlider,
  MuiChip,
  NextLink,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, alpha, LinkProps } from '@mui/material'
import { Components, PaletteOptions } from '@mui/material/styles'

// import { Signika } from 'next/font/google'
// import local from 'next/font/local'
// Signika({ variable: true, })
// local({ src: })
// <link
// rel='preload'
// href='/fonts/Signika.woff2'
// as='font'
// type='font/woff2'
// crossOrigin=''
// />
// <link
// rel='preload'
// href='/fonts/Allison.woff2'
// as='font'
// type='font/woff2'
// crossOrigin=''
// />

declare module '@mui/material/styles' {
  interface TypographyVariants {
    allison1: React.CSSProperties
    allison2: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    allison1?: React.CSSProperties
    allison2?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    allison1: true
    allison2: true
  }
}

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#CB8792',
    contrastText: '#FFFFFF',
    dark: '#E2B9BB',
  },
  secondary: {
    main: '#4D7F71',
    light: '#CADBD3',
    contrastText: '#ffffff',
  },
  background: {
    default: '#FBF8F5',
    paper: '#ffffff',
    image: '#ffffff',
  },
  divider: '#00000015',
  success: {
    main: '#01d26a',
  },
  action: {
    hoverOpacity: 0.12,
  },
  text: {
    primary: '#1A1919',
    secondary: '#00000055',
    disabled: '#00000032',
  },
}

const fontSize = (from: number, to: number) =>
  breakpointVal('fontSize', from, to, themeBaseDefaults.breakpoints.values)

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    shape: { borderRadius: 4 },
    typography: {
      fontFamily:
        'Signika,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
      // @see docs typography.md
      h1: {
        ...fontSize(28, 55),
        fontWeight: 700,
        fontVariationSettings: "'wght' 660",
        lineHeight: 1.22,
      },
      h2: {
        ...fontSize(25, 44),
        fontWeight: 700,
        fontVariationSettings: "'wght' 630",
        lineHeight: 1.35,
      },
      h3: {
        ...fontSize(22, 30),
        fontWeight: 700,
        fontVariationSettings: "'wght' 660",
        lineHeight: 1.35,
      },
      h4: {
        ...fontSize(18, 26),
        fontWeight: 550,
        fontVariationSettings: "'wght' 550",
        lineHeight: 1.55,
      },
      h5: {
        ...fontSize(17, 20),
        fontWeight: 650,
        fontVariationSettings: "'wght' 650",
        lineHeight: 1.55,
      },
      h6: {
        ...fontSize(17, 20),
        fontWeight: 550,
        fontVariationSettings: "'wght' 510",
        lineHeight: 1.8,
      },
      subtitle1: {
        ...fontSize(16, 19),
        fontWeight: 450,
        fontVariationSettings: "'wght' 460",
        lineHeight: 1.5,
      },
      fontWeightBold: 600,
      body1: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        ...fontSize(14, 18),
        lineHeight: 1.7,
      },
      subtitle2: {
        ...fontSize(14, 16),
        fontWeight: 500,
        fontVariationSettings: "'wght' 520",
        lineHeight: 1.5,
      },
      body2: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        ...fontSize(13, 15),
        lineHeight: 1.7,
      },
      caption: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
      },
      button: {},
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 14),
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
      allison1: {
        ...fontSize(68, 135),
        fontFamily:
          'Allison,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        fontWeight: 400,
        display: 'inline-block',
        padding: '0 0.15em',
        lineHeight: 1.3,
        letterSpacing: '1px',
      },
      allison2: {
        ...fontSize(55, 110),
        fontFamily:
          'Allison,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        fontWeight: 400,
        display: 'inline-block',
        padding: '0 0.15em',
        letterSpacing: '1px',
        lineHeight: 1.3,
      },
    },
    spacings: {
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 36),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(26, 80),
      xxl: responsiveVal(28, 80),
    },
    page: {
      horizontal: responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: '46px',
      headerHeightMd: '96px',
      appBarHeightMd: '80px',
      appBarInnerHeightMd: '110px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components<Theme> => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: 'scroll',
        '@font-face': {
          fontFamily: 'Signika',
          src: `url('/fonts/Signika.woff2') format('woff2')`,
          fontWeight: '400 600',
          fontDisplay: 'swap',
        },
      },
      '::selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '::-moz-selection': { background: alpha(theme.palette.primary.main, 0.6) },
      a: { color: theme.palette.text.primary },
      '#__next': {
        position: 'relative',
      },
      '@font-face': {
        fontFamily: 'Allison',
        src: `url('/fonts/Allison.woff2') format('woff2')`,
        fontWeight: '200 700',
        fontDisplay: 'swap',
      },
      '.allison': {
        fontFamily:
          'Allison,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        fontWeight: 400,
        fontSize: '2.5em',
        padding: '0 0.15em 0.2em 0.15em',
        lineHeight: '0.7em',
        display: 'inline-block',
        letterSpacing: '1px',
        textTransform: 'lowerCase',
      },
      '.allison-cap': {
        textTransform: 'capitalize',
      },
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: { defaultProps: { component: NextLink } as LinkProps },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: {
      color: 'inherit',
    },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      {
        props: { variant: 'contained' },
        style: { borderRadius: theme.shape.borderRadius * 1.5 },
      },
      {
        props: { variant: 'contained', size: 'large' },
        style: {
          ...fontSize(18, 26),
          fontWeight: 700,
          padding: `${responsiveVal(10, 12)} ${responsiveVal(10, 25)}`,
          fontVariationSettings: "'wght' 560",
          boxShadow: theme.shadows[4],
        },
      },
      {
        props: { variant: 'contained', size: 'medium' },
        style: {
          fontFamily: theme.typography.h1.fontFamily,
          boxShadow: theme.shadows[4],
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          fontFamily: theme.typography.h1.fontFamily,
          borderRadius: theme.shape.borderRadius * 1.5,
          backgroundColor: theme.palette.background.paper,
          fontWeight: 600,
          boxShadow: theme.shadows[4],
        },
      },
      {
        props: { variant: 'text' },
        style: {
          fontFamily: theme.typography.h1.fontFamily,
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: alpha(theme.palette.divider, theme.palette.action.hoverOpacity),
          },
        },
      },
      {
        props: { variant: 'inline', color: 'primary' },
        style: {
          color: theme.palette.primary.main,
          '&:hover:not(.Mui-disabled)': { backgroundColor: `${theme.palette.primary.main}30` },
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },

    variants: [
      ...MuiFabSizes,
      {
        props: { variant: 'circular', color: 'default' },
        style: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        },
      },
    ],
  },

  MuiTextField: {
    defaultProps: { color: 'secondary' },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: theme.shape,
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [
      ...MuiChip,
      {
        props: { variant: 'filled', color: 'default' },
        style: {
          ...theme.typography.body1,
          color: theme.palette.primary.contrastText,
          borderRadius: 5,
          padding: 0,
          paddingBottom: 2,
          marginRight: theme.spacings.xs,
          backdropFilter: `blur(200px) saturate(500%) contrast(40%) brightness(120%)`,
        },
      },
    ],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      thumb: {
        boxShadow: theme.shadows[6],
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  Money: {
    defaultProps: {
      round: true,
      formatOptions: {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
    },
  },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme) as Components
