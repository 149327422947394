import Script from 'next/script'
import { useStoreData } from '../../lib/hooks/useStoreData'

export function KlaviyoScript() {
  const { mode } = useStoreData()
  const klaviyoPublicApiKey = mode === 'b2b' ? 'WXNnxF' : 'VJHnfK'

  return (
    <Script
      async
      src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPublicApiKey}`}
    />
  )
}
