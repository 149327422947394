import { useStoreData } from '../../lib/hooks/useStoreData'

export function CookieFirstScript() {
  const { mode } = useStoreData()
  const endpoint = import.meta.graphCommerce.magentoEndpoint

  if (mode === 'b2b' || endpoint.includes('rc.vaessen-creative.com')) return null

  return (
    <>
      {/* The below script tag can not be loaded aynschronously as autoblocking will no longer work */}
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script src='https://consent.cookiefirst.com/sites/vaessen-creative.com-773d54d7-dfff-419c-872f-bd4af89ae5be/consent.js' />
    </>
  )
}
