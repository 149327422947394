import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'

export function useStoreData() {
  const { data } = useQuery(StoreConfigDocument, { fetchPolicy: 'cache-only' })

  const mode: 'b2b' | 'b2c' = data?.storeConfig?.store_code?.includes('b2b') ? 'b2b' : 'b2c'

  return {
    mode,
  }
}
