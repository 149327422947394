import { dvh } from '@graphcommerce/framer-utils'
import { useFabSize } from '@graphcommerce/next-ui'
import { GlobalStyles } from '@mui/material'
import Script from 'next/script'

export const CustomerServiceChat = () => {
  const fabIconSize = useFabSize('responsive')

  return (
    <>
      <Script
        strategy='afterInteractive'
        id='ze-snippet'
        src='https://static.zdassets.com/ekr/snippet.js?key=eab880e5-b60c-4ba9-8bd6-9fe56b4d9aad'
      />
      <GlobalStyles
        styles={{
          'body:has(.LayoutOverlayBase-backdrop[style*="opacity: 1"]) iframe[data-product="web_widget"] ~ div > div > iframe':
            {
              display: 'none !important',
            },
          '& iframe[data-product="web_widget"] ~ div > div > iframe': {
            zIndex: '1 !important',
          },
          '& iframe[data-product="web_widget"] ~ div > div:nth-of-type(1) > iframe': {
            right: '20px !important',

            '@media (max-width: 960px)': {
              right: '0 !important',
              bottom: `calc(40px + ${fabIconSize}) !important`,
              maxHeight: `calc(${dvh(100)} - 150px) !important`,
            },
          },
          '& iframe[data-product="web_widget"] ~ div > div:nth-of-type(2) > iframe': {
            transform: `scale(${50 / 64}) !important`,

            '@media (max-width: 960px)': {
              bottom: `calc(30px + ${fabIconSize}) !important`,
              right: '9px !important',
              transform: `scale(${45 / 64}) !important`,
            },
          },
        }}
      />
    </>
  )
}
